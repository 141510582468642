
import React from 'react';
import PropTypes from 'prop-types';

const IconOnlinePayment = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 38 32" fill="none">
      <path className={className} fillRule="evenodd" clipRule="evenodd" d="M26.1109 7.78633C26.0294 6.08522 24.6183 4.73096 22.8896 4.73096H15.8065L15.6521 4.73444C13.8155 4.81793 12.5814 6.39521 12.5814 8.82359V13.2305H13.7197V11.1528H24.9764V21.4792H13.7197V18.5191H12.5814V23.8077L12.5851 23.9633C12.6666 25.6644 14.0777 27.0187 15.8065 27.0187H22.8896L23.0458 27.015C24.7544 26.9338 26.1146 25.5289 26.1146 23.8077V7.9419L26.1109 7.78633ZM13.7197 22.6125V23.8077C13.7197 24.9127 14.586 25.8162 15.6794 25.8816L15.8065 25.8854H22.8896C23.9994 25.8854 24.9069 25.0228 24.9726 23.9343L24.9764 23.8077V22.6125H13.7197ZM24.9764 10.0196H13.7197V8.82359L13.7225 8.63493C13.7769 6.83947 14.6129 5.86423 15.8065 5.86423H22.8896L23.0167 5.86802C24.11 5.93343 24.9764 6.83693 24.9764 7.9419V10.0196ZM11.0044 15.3082H17.0882L15.9132 14.1381L16.718 13.3367L19.2665 15.874L16.718 18.4113L15.9132 17.6099L17.0867 16.4415H11.0044V15.3082ZM21.1257 8.50854V7.37526H17.5849V8.50854H21.1257Z" fill={fill} />
    </svg>
  );
};

IconOnlinePayment.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconOnlinePayment.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconOnlinePayment {...props} />;

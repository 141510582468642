import React from 'react';
import PropTypes from 'prop-types';

const CertifiedBadgeIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 80 80">
    <g fill="none" fillRule="evenodd">
      <path fill="#CD7F32" d="M35.015 31.447h10.981V8.5H35.015zM59.869 52.743c0 10.595-8.59 19.184-19.185 19.184-10.595 0-19.184-8.589-19.184-19.184 0-10.596 8.589-19.185 19.184-19.185s19.185 8.59 19.185 19.185" />
      <path fill="#FFF" d="M55.054 52.743c0 7.937-6.434 14.37-14.37 14.37-7.937 0-14.37-6.433-14.37-14.37 0-7.937 6.433-14.37 14.37-14.37 7.936 0 14.37 6.433 14.37 14.37" />
      <path stroke="#000" d="M59.869 52.743c0 10.595-8.59 19.184-19.185 19.184-10.595 0-19.184-8.589-19.184-19.184 0-10.596 8.589-19.185 19.184-19.185s19.185 8.59 19.185 19.185z" />
      <path fill="#CD7F32" d="M49.795 41.632a14.303 14.303 0 0 1 3.259 9.11c0 7.937-6.434 14.37-14.37 14.37a14.313 14.313 0 0 1-9.111-3.258 14.34 14.34 0 0 0 11.112 5.26c7.936 0 14.37-6.434 14.37-14.37 0-4.479-2.05-8.477-5.26-11.112" />
      <path stroke="#000" strokeLinecap="round" d="M33.326 31.447l-4.294-4.294V8.501H52.33v18.722l-4.083 4.084" />
      <path stroke="#000" d="M55.054 52.743c0 7.937-6.434 14.37-14.37 14.37-7.937 0-14.37-6.433-14.37-14.37 0-7.937 6.433-14.37 14.37-14.37 7.936 0 14.37 6.433 14.37 14.37z" />
      <path fill="#CD7F32" d="M41.383 44.18l2.063 4.18a.78.78 0 0 0 .588.426l4.614.67a.78.78 0 0 1 .432 1.33l-3.338 3.255a.782.782 0 0 0-.224.69l.788 4.595a.78.78 0 0 1-1.132.823l-4.126-2.17a.777.777 0 0 0-.726 0l-4.126 2.17a.78.78 0 0 1-1.132-.823l.788-4.594a.783.783 0 0 0-.225-.691l-3.338-3.254a.78.78 0 0 1 .433-1.33l4.613-.671a.778.778 0 0 0 .587-.426l2.064-4.18a.78.78 0 0 1 1.398 0" />
      <path stroke="#000" strokeLinecap="round" d="M41.383 44.18l2.063 4.18a.78.78 0 0 0 .588.426l4.614.67a.78.78 0 0 1 .432 1.33l-3.338 3.255a.782.782 0 0 0-.224.69l.788 4.595a.78.78 0 0 1-1.132.823l-4.126-2.17a.777.777 0 0 0-.726 0l-4.126 2.17a.78.78 0 0 1-1.132-.823l.788-4.594a.783.783 0 0 0-.225-.691l-3.338-3.254a.78.78 0 0 1 .433-1.33l4.613-.671a.778.778 0 0 0 .587-.426l2.064-4.18a.78.78 0 0 1 1.398 0z" />
    </g>
  </svg>
);

CertifiedBadgeIcon.defaultProps = {
  className: '',
  width: 50,
  height: 50,
};

CertifiedBadgeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default props => <CertifiedBadgeIcon {...props} />;

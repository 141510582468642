
import React from 'react';
import PropTypes from 'prop-types';

const IconInmobile = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 86 86" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M32.0293 62.9997H61.275V24.6092C61.275 23.7172 60.5295 23 59.6173 23H33.6869C32.7748 23 32.0293 23.7172 32.0293 24.6092V62.9997ZM58.8858 25.3213V60.6785H34.4184V25.3213H58.8858Z" className={className} fill={fill} />
      <path d="M56.8756 44.9807V42.6594H36.499V44.9807H56.8756Z" className={className} fill={fill} />
      <path d="M56.8756 39.103V36.7817H36.499V39.103H56.8756Z" className={className} fill={fill} />
      <path d="M56.8756 33.2256V30.9043H36.499V33.2256H56.8756Z" className={className} fill={fill} />
      <path d="M56.8756 50.8581V48.5369H36.499V50.8581H56.8756Z" className={className} fill={fill} />
      <path d="M48.0781 63.0002V60.679H24.8521V63.0002H48.0781Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M41.7529 63.0003H51.5508V55.1114C51.5508 54.2194 50.8053 53.5022 49.8931 53.5022H43.4105C42.4984 53.5022 41.7529 54.2194 41.7529 55.1114V63.0003ZM44.1421 55.8235H49.1616V60.679H44.1421V55.8235Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.3989 56.6414H26.0098V62.9996H28.3989V56.6414Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.4087 51.5225C31.4087 49.268 29.523 47.4463 27.2043 47.4463C24.8857 47.4463 23 49.268 23 51.5225V54.6624C23 56.9169 24.8857 58.7386 27.2043 58.7386C29.523 58.7386 31.4087 56.9169 31.4087 54.6624V51.5225ZM25.3891 51.5225C25.3891 50.5565 26.1985 49.7676 27.2043 49.7676C28.2102 49.7676 29.0196 50.5565 29.0196 51.5225V54.6624C29.0196 55.6284 28.2102 56.4173 27.2043 56.4173C26.1985 56.4173 25.3891 55.6284 25.3891 54.6624V51.5225Z" className={className} fill={fill} />
    </svg>
  );
};

IconInmobile.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconInmobile.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => (
  <IconInmobile {...props} />
);

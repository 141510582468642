
import React from 'react';
import PropTypes from 'prop-types';

const IconFlashlight = () => (
  <svg
    width="23"
    height="31"
    viewBox="0 0 23 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75781 11.9141L11.3203 2.61719V8.08594H16.2422L9.95312 17.3828V11.9141H4.75781Z"
      stroke="#737373"
      fill="none"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
IconFlashlight.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
IconFlashlight.defaultProps = {
  fill: '#737373',
  width: 23,
  height: 31,
  className: '',
};
export default (props) => <IconFlashlight {...props} />;

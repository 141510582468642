import React from 'react';
import PropTypes from 'prop-types';

const SilverBadgeIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 80 80">
    <g fill="none" fillRule="evenodd">
      <path fill="#C1C1C1" d="M35.015 29.448h10.981V6.5H35.015zM59.869 50.743c0 10.595-8.59 19.184-19.185 19.184-10.595 0-19.184-8.589-19.184-19.184 0-10.596 8.589-19.185 19.184-19.185s19.185 8.59 19.185 19.185" />
      <path fill="#FFF" d="M55.054 50.743c0 7.937-6.434 14.37-14.37 14.37-7.937 0-14.37-6.433-14.37-14.37 0-7.937 6.433-14.37 14.37-14.37 7.936 0 14.37 6.433 14.37 14.37" />
      <path stroke="#000" d="M59.869 50.743c0 10.595-8.59 19.184-19.185 19.184-10.595 0-19.184-8.589-19.184-19.184 0-10.596 8.589-19.185 19.184-19.185s19.185 8.59 19.185 19.185z" />
      <path fill="#C1C1C1" d="M49.795 39.632a14.303 14.303 0 0 1 3.259 9.11c0 7.937-6.434 14.37-14.37 14.37a14.313 14.313 0 0 1-9.111-3.258 14.34 14.34 0 0 0 11.112 5.259c7.936 0 14.37-6.433 14.37-14.37 0-4.478-2.05-8.476-5.26-11.111" />
      <path stroke="#000" strokeLinecap="round" d="M33.326 29.447l-4.294-4.294V6.5H52.33v18.723l-4.083 4.083" />
      <path stroke="#000" d="M55.054 50.743c0 7.937-6.434 14.37-14.37 14.37-7.937 0-14.37-6.433-14.37-14.37 0-7.937 6.433-14.37 14.37-14.37 7.936 0 14.37 6.433 14.37 14.37z" />
      <path fill="#C1C1C1" d="M41.383 42.18l2.063 4.18a.78.78 0 0 0 .588.426l4.614.67a.78.78 0 0 1 .432 1.33l-3.338 3.255a.782.782 0 0 0-.224.69l.788 4.595a.78.78 0 0 1-1.132.823l-4.126-2.17a.777.777 0 0 0-.726 0l-4.126 2.17a.78.78 0 0 1-1.132-.823l.788-4.594a.783.783 0 0 0-.225-.691l-3.338-3.254a.78.78 0 0 1 .433-1.33l4.613-.671a.778.778 0 0 0 .587-.426l2.064-4.18a.78.78 0 0 1 1.398 0" />
      <path stroke="#000" d="M41.383 42.18l2.063 4.18a.78.78 0 0 0 .588.426l4.614.67a.78.78 0 0 1 .432 1.33l-3.338 3.255a.782.782 0 0 0-.224.69l.788 4.595a.78.78 0 0 1-1.132.823l-4.126-2.17a.777.777 0 0 0-.726 0l-4.126 2.17a.78.78 0 0 1-1.132-.823l.788-4.594a.783.783 0 0 0-.225-.691l-3.338-3.254a.78.78 0 0 1 .433-1.33l4.613-.671a.778.778 0 0 0 .587-.426l2.064-4.18a.78.78 0 0 1 1.398 0zM40.684 71.85a23.21 23.21 0 0 1-10.94-2.72" />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M36.965 71.868c-.834 2.484-2.595 3.715-5.79 3.007 1.259-3.02 3.278-3.753 5.79-3.007zM29.944 69.44c-2.425.995-4.508.469-6.062-2.41 3.107-1.026 4.983.022 6.062 2.41z" />
      <path stroke="#000" d="M40.721 71.85a23.21 23.21 0 0 0 10.941-2.72" />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M44.44 71.868c.835 2.484 2.596 3.715 5.79 3.007-1.258-3.02-3.277-3.753-5.79-3.007zM51.462 69.44c2.425.995 4.508.469 6.062-2.41-3.107-1.026-4.983.022-6.062 2.41z" />
      <path fill="#FFF" d="M42.186 71.85a1.503 1.503 0 1 1-3.005-.002 1.503 1.503 0 0 1 3.005.002" />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M42.186 71.85a1.503 1.503 0 1 1-3.005-.002 1.503 1.503 0 0 1 3.005.002z" />
    </g>
  </svg>
);

SilverBadgeIcon.defaultProps = {
  className: '',
  width: 50,
  height: 50,
};

SilverBadgeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default props => <SilverBadgeIcon {...props} />;


import React from 'react';
import PropTypes from 'prop-types';

const IconCurrencyTag = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <g transform="rotate(45 10.257 39.52)">
          <path d="M9 1l8 8H1z" />
          <path
            fill={fill}
            className={className}
            fillRule="nonzero"
            d="M1 10.12V31h16V10.12L9 2l-8 8.12zm-1-.41L9 .575l9 9.135V32H0V9.71z"
          />
          <path
            fill={fill}
            className={className}
            d="M6.653 18.885l.732-.732 1.755-1.756-.734-.733-1.968 1.968-1.174-1.174-.732.731 1.175 1.174a2.18 2.18 0 0 0 3.075 3.09l1.17-1.171a1.142 1.142 0 0 1 1.614 1.614l-.219.22-.732.731L9 24.485l.722.723 1.415-1.415 1.399 1.4.707-.708-1.4-1.399.457-.456a2.178 2.178 0 0 0-.005-3.077 2.178 2.178 0 0 0-3.077-.005l-1.17 1.17a1.141 1.141 0 0 1-1.614-1.614l.219-.22z"
          />
          <circle cx="9" cy="8" r="1" fill={fill} className={className} />
        </g>
      </g>
    </svg>
  );
};

IconCurrencyTag.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconCurrencyTag.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconCurrencyTag {...props} />;

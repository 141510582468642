import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@andes/autocomplete';
import { useI18n } from 'nordic/i18n';
import { SearchBox } from '@andes/search-box';
import Form from '@andes/form';
import ArrowLeft24 from '@andes/icons/ArrowLeft24';
import useSuggestions from './hooks/useSuggestions';

const SearchForm = props => {
  const {
    baseUrl,
    initialQueryTerm,
    isMobile,
  } = props;

  const { i18n } = useI18n();

  const {
    placeholder,
    queryTerm,
    suggestions,
    events,
  } = useSuggestions({
    baseUrl,
    initialQueryTerm,
  });

  const srLabelSearchbox = i18n.gettext('Buscar');

  const SearchWrapper = isMobile ? 'div' : React.Fragment;

  return (
    <SearchWrapper className="search-form-container-mobile">
      {isMobile && <ArrowLeft24 />}

      <Form
        className="search-form"
        onSubmit={events.onSubmit}
      >
        <Autocomplete
          srLabel={placeholder}
          className="andes-autocomplete--cdp"
          suggestions={suggestions}
          onKeyDown={events.onKeyDown}
          onSelect={(_, { name }) => events.onSelect(name)}
        >
          <SearchBox autoFocus={false} srLabel={srLabelSearchbox} size={isMobile ? 'small' : 'large'} value={queryTerm} onChange={({ target: { value } }) => events.onChange(value)} />
        </Autocomplete>
      </Form>
    </SearchWrapper>
  );
};

SearchForm.propTypes = {
  baseUrl: PropTypes.string,
  initialQueryTerm: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default memo(SearchForm);

import React from 'react';
import PropTypes from 'prop-types';

const IconShakeHands = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={className}
        d="M11 14H59.4614V18.5336H64.3321V51H15.591V46.4664H11V14ZM59.4614 46.4664V21.2251L61.6386 21.2243V48.3071H18.2818L18.2828 46.4664H59.4614ZM56.7691 43.7733V16.6923H13.6923V43.7733H56.7691ZM29.3972 26.4239C29.3972 24.2956 31.0496 22.4943 33.8137 22.148V19.3846H36.1021V22.1682C37.7922 22.3835 39.2242 23.0299 40.3543 24.0744L38.667 26.2957C37.5136 25.2278 35.9758 24.7365 34.5234 24.7365C33.2205 24.7365 32.4943 25.3132 32.4943 26.1676C32.4943 27.0776 33.7733 27.3795 35.2926 27.7381L35.335 27.7481L35.3392 27.7491C37.6881 28.3043 40.6747 29.0103 40.6747 32.2335C40.6747 34.5696 39.1813 36.3906 36.1021 36.7426V40.0042H33.8137V36.7461C31.6731 36.5245 30.0779 35.6877 28.9487 34.5403L30.5933 32.2335C31.5972 33.2801 33.1564 34.1558 35.1214 34.1558C36.7874 34.1558 37.599 33.3869 37.599 32.5753C37.599 31.5549 36.3404 31.2451 34.8324 30.874L34.7156 30.8452L34.6212 30.8236C32.3075 30.2935 29.3972 29.6267 29.3972 26.4239ZM51.2491 30.2175C51.2491 31.4813 50.2246 32.5059 48.9607 32.5059C47.6968 32.5059 46.6722 31.4813 46.6722 30.2175C46.6722 28.9536 47.6968 27.929 48.9607 27.929C50.2246 27.929 51.2491 28.9536 51.2491 30.2175ZM21.499 32.5064C22.7629 32.5064 23.7875 31.4818 23.7875 30.218C23.7875 28.9541 22.7629 27.9295 21.499 27.9295C20.2351 27.9295 19.2106 28.9541 19.2106 30.218C19.2106 31.4818 20.2351 32.5064 21.499 32.5064Z"
        fill={fill}
      />
    </svg>

  );
};

IconShakeHands.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconShakeHands.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default (props) => <IconShakeHands {...props} />;

import React from 'react';
import PropTypes from 'prop-types';

const IconLaptop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="32"
    viewBox="0 0 25 25"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      stroke="none"
      fill="#737373"
      d="M4.5 3.75H19.5C20.7426 3.75 21.75 4.75736 21.75 6V15.75H23.25V18C23.25 19.2426 22.2426 20.25 21 20.25H3C1.75736 20.25 0.75 19.2426 0.75 18V15.75H2.25V6C2.25 4.75736 3.25736 3.75 4.5 3.75ZM3.75 15.75H20.25V6C20.25 5.58579 19.9142 5.25 19.5 5.25H4.5C4.08579 5.25 3.75 5.58579 3.75 6V15.75ZM21.75 17.25H2.25V18C2.25 18.4142 2.58579 18.75 3 18.75H21C21.4142 18.75 21.75 18.4142 21.75 18V17.25Z"
    />
  </svg>
);
IconLaptop.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
IconLaptop.defaultProps = {
  fill: '#737373',
  width: 35,
  height: 32,
  className: '',
};
export default (props) => <IconLaptop {...props} />;

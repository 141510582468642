
import React from 'react';
import PropTypes from 'prop-types';

const IconFinances = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 86 86" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.5814 31.7041C18.5814 26.897 22.4784 23 27.2855 23C32.0926 23 35.9896 26.897 35.9896 31.7041C35.9896 36.5112 32.0926 40.4082 27.2855 40.4082C22.4784 40.4082 18.5814 36.5112 18.5814 31.7041ZM27.2855 25.7907C24.0196 25.7907 21.3721 28.4382 21.3721 31.7041C21.3721 34.97 24.0196 37.6175 27.2855 37.6175C30.5514 37.6175 33.1989 34.97 33.1989 31.7041C33.1989 28.4382 30.5514 25.7907 27.2855 25.7907Z" className={className} fill={fill} />
      <path d="M53.1119 45.175V42.3843H48.3488V45.175H53.1119Z" className={className} fill={fill} />
      <path d="M60.5538 42.3843V45.175H55.7907V42.3843H60.5538Z" className={className} fill={fill} />
      <path d="M49.391 50.7564V47.9657H44.6279V50.7564H49.391Z" className={className} fill={fill} />
      <path d="M56.8329 47.9657V50.7564H52.0698V47.9657H56.8329Z" className={className} fill={fill} />
      <path d="M64.2747 50.7564V47.9657H59.5116V50.7564H64.2747Z" className={className} fill={fill} />
      <path d="M65.6508 54.6279V57.4186H43.3252V54.6279H65.6508Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M60.907 33.2326H65V38.4856L71.7907 43.7387V63H37.186V43.7387L43.9767 38.4856V33.2326H58.1163V29.5116H53.9302V26.7209H65.093V29.5116H60.907V33.2326ZM46.7674 39.8551V36.0233H62.2093V39.8551L69 45.1081V60.2093H39.9767V45.1081L46.7674 39.8551Z" className={className} fill={fill} />
      <path d="M33.0931 43.7206C31.2955 43.1354 29.3285 42.8113 27.1945 42.8113C22.5313 42.8113 18.6654 44.359 15.622 46.7971C13.9552 48.1306 13 50.1765 13 52.3462V57.0824H33.0931V54.2913H15.7907V52.3462C15.7907 51.013 16.3697 49.7728 17.3661 48.9757C19.9436 46.9107 23.2128 45.602 27.1945 45.602C29.3729 45.602 31.338 45.9937 33.0931 46.6866V43.7206Z" className={className} fill={fill} />
    </svg>
  );
};

IconFinances.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconFinances.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconFinances {...props} />;

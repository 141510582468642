
import React from 'react';
import PropTypes from 'prop-types';

const IconMegaphone = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 86 86">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.0683 54.9774V23L18 33.2164V44.2502L26.5659 46.7979V63H36.4606V49.7407L54.0683 54.9774ZM51.3119 51.282L36.4606 46.865V46.825L35.4514 46.5648L20.7564 42.1943V35.3004L51.3119 26.6455V51.282ZM33.7042 48.9609V60.2436H29.3223V47.8315L33.7042 48.9609Z"
        className={className}
        fill={fill}
      />
      <path
        d="M67.8687 37.5981H56.5464V40.3545H67.8687V37.5981Z"
        className={className}
        fill={fill}
      />
      <path d="M55.9859 37.3184L64.5601 32.4108L63.1908 30.0186L54.6167 34.9262L55.9859 37.3184Z" className={className} fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.1908 48.6416L54.6167 43.734L55.9859 41.3418L64.5601 46.2494L63.1908 48.6416Z"
        className={className}
        fill={fill}
      />
    </svg>
  );
};

IconMegaphone.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconMegaphone.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconMegaphone {...props} />;

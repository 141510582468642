import React, { useState, useEffect } from 'react';
import { CancelToken, isCancel } from 'nordic/restclient/cancel';
import { useI18n } from 'nordic/i18n';
import RestClient from 'nordic/restclient';
import Search16 from '@andes/icons/Search16';

const restclient = RestClient({
  timeout: 20000,
  baseURL: '/api',
});

const useSuggestions = ({ baseUrl, initialQueryTerm }) => {
  const { i18n } = useI18n();
  const srLabelText = i18n.gettext('Buscar');
  const placeholder = i18n.gettext('Encuentra una solución');
  const searchButton = (
    <button
      type="submit"
      className="nav-search-btn"
      aria-label={srLabelText}
      tabIndex="0"
    >
      <Search16 color="#BFBFBF" />
    </button>
  );

  const [queryTerm, setQueryTerm] = useState(initialQueryTerm);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const source = CancelToken.source();

    const fetchSuggestionsData = async () => {
      if (queryTerm === initialQueryTerm) return;

      const params = {
        q: queryTerm,
      };

      try {
        const { data: suggestionsData } = await restclient.get(
          '/autocomplete',
          {
            cancelToken: source.token,
            params,
          },
        );

        setSuggestions(suggestionsData);
      } catch (error) {
        if (!isCancel(error)) {
          setSuggestions([]);
        }
      }
    };

    fetchSuggestionsData();

    return () => {
      source.cancel();
    };
  }, [queryTerm]);

  const redirectSearch = (term) => {
    const sanitizedTerm = term.replace(/^[!&@#$%*()_+\-=[\]{};:"\\|,.<>\/?~`]+/, '').replace(/ +/g, ' ').replace(/[!@#$%*()_+\-=[\]{};:"\\|,.<>\/?~`]|(?:[^\S\r\n]$)/, '');
    const encodedTerm = encodeURIComponent(sanitizedTerm.trim());
    window.location.href = `${baseUrl}/search?q=${encodedTerm}`;
  };

  const onChange = value => {
    setQueryTerm(value);
  };

  const onSubmit = event => {
    event.preventDefault();

    redirectSearch(queryTerm);
  };

  const onKeyDown = ({ keyCode, target: { value } }) => {
    const enterKey = 13;
    if (keyCode === enterKey) {
      redirectSearch(value);
    }
  };

  const onSelect = value => redirectSearch(value);

  return {
    searchButton,
    placeholder,
    queryTerm,
    suggestions,
    events: { onChange, onKeyDown, onSelect, onSubmit },
  };
};

export default useSuggestions;

const isMobile = deviceType => deviceType === 'mobile' || deviceType === 'tablet';

const isValidUrl = url => {
  try {
    return Boolean(new URL(url));
  } catch (error) {
    return false;
  }
};

const getFormattedUrlPropsCategory = (baseUrl, url) => {
  if (url.startsWith('http')) {
    return {
      href: url,
      target: '_blank',
      rel: 'noreferrer',
    };
  }

  return {
    href: `${baseUrl}/search/${url}`,
  };
};

const getYouTubeVideoIdByUrl = url => {
  // eslint-disable-next-line security/detect-unsafe-regex
  const regex = new RegExp(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/);
  const matches = regex.exec(url);

  return (matches && matches[1]) ? matches[1] : null;
};

const getAppStoreDomain = (country) => {
  let url;
  switch (country) {
    case 'MLA':
      url = 'https://www.mercadolibre.com.ar/hub-engine/hubs/my-apps';
      break;
    case 'MLB':
      url = 'https://www.mercadolivre.com.br/hub-engine/hubs/my-apps';
      break;
    case 'MCO':
      url = 'https://www.mercadolibre.com.co/hub-engine/hubs/my-apps';
      break;
    case 'MLM':
      url = 'https://www.mercadolibre.com.mx/hub-engine/hubs/my-apps';
      break;
    case 'MLU':
      url = 'https://www.mercadolibre.com.uy/hub-engine/hubs/my-apps';
      break;
    case 'MLC':
      url = 'https://www.mercadolibre.cl/hub-engine/hubs/my-apps';
      break;
    case 'MPE':
      url = 'https://www.mercadolibre.com.pe/hub-engine/hubs/my-apps';
      break;
    default:
      url = 'https://www.mercadolibre.com.ar/hub-engine/hubs/my-apps';
      break;
  }
  return url;
};

const getCategoryParamVariable = (categoryParam) => {
  let categoryParamVariable = '';
  switch (categoryParam) {
    case 'gestao-de-ecommerce':
      categoryParamVariable = 'gestaoDeEcommerce';
      break;
    case 'gestion-de-ecommerce':
      categoryParamVariable = 'gestionDeEcommerce';
      break;
    case 'marketing-e-publicidade':
      categoryParamVariable = 'marketingEPublicidade';
      break;
    case 'marketing-y-publicidad':
      categoryParamVariable = 'marketingYPublicidad';
      break;
    case 'automoveis':
      categoryParamVariable = 'automoveis';
      break;
    case 'vehiculos':
      categoryParamVariable = 'vehiculos';
      break;
    case 'financas-e-contabilidade':
      categoryParamVariable = 'financasEContabilidade';
      break;
    case 'finanzas-y-contabilidad':
      categoryParamVariable = 'finanzasYContabilidad';
      break;
    case 'imoveis':
      categoryParamVariable = 'imoveis';
      break;
    case 'inmuebles':
      categoryParamVariable = 'inmuebles';
      break;
    case 'mercado-shops':
      categoryParamVariable = 'mercadoShops';
      break;
    case 'pagamentos':
      categoryParamVariable = 'pagamentos';
      break;
    case 'pagos':
      categoryParamVariable = 'pagos';
      break;
    case 'outros-servicos':
      categoryParamVariable = 'outrosServicos';
      break;
    default:
      categoryParamVariable = 'gestionDeEcommerce';
      break;
  }

  return categoryParamVariable;
};

const getUrlWithParams = (url, params) => {
  const searchParams = new URLSearchParams(params);
  const separator = url.includes('?') ? '&' : '?';

  return `${url}${separator}${searchParams.toString()}`;
};

module.exports = {
  getFormattedUrlPropsCategory,
  getYouTubeVideoIdByUrl,
  getAppStoreDomain,
  isMobile,
  isValidUrl,
  getCategoryParamVariable,
  getUrlWithParams,
};

/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabContent } from '@andes/tabs';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';
import { Title } from '@andes/typography';
import { useI18n } from 'nordic/i18n';
import { isMobile } from '../../../../../utils';
import CardAppItem from '../../../../components/CardAppItem';

const VISIBLE_SLIDES_PER_VIEW = 6;

const EspecializadasView = ({ deviceType, apps = {}, solutions = {}, baseUrl, siteId }) => {
  const { i18n } = useI18n();

  let carouselFreeProps = {
    slidesPerView: VISIBLE_SLIDES_PER_VIEW,
    pagination: false,
  };

  const content = {
    ML: [...(apps.ML || []), ...(solutions.ML || [])],
    MS: [...(apps.MS || []), ...(solutions.MS || [])],
    MP: [...(apps.MP || []), ...(solutions.MP || [])],
  };

  const keys = Object.keys(content);

  const filtered = keys.filter((key) => content[key] && content[key].length > 0);

  if (filtered.length === 0) return null;

  if (isMobile(deviceType)) {
    carouselFreeProps = {
      arrows: false,
      mobile: true,
      type: 'full',
    };
  }

  const filteredTitles = {
    ML: (siteId === 'MLB') ? 'Mercado Livre' : 'Mercado Libre',
    MS: 'Mercado Shops',
    MP: 'Mercado Pago',
  };

  const renderItems = (appItems, businessUnit) => {
    const appsFiltered = appItems[`${businessUnit}`];

    if (isMobile(deviceType) || appsFiltered.length > VISIBLE_SLIDES_PER_VIEW) {
      return (
        <CarouselFree
          key={`carousel-specialized-${businessUnit.toLowerCase()}`}
          className="section-home-especializadas__slider"
          srLabel="Carousel"
          {...carouselFreeProps}
        >
          {appsFiltered.map((item, itemIndex) => (
            <CarouselFreeSlide key={itemIndex}>
              <CardAppItem
                {...item}
                baseUrl={baseUrl}
                isSecondary
                showCategorization
              />
            </CarouselFreeSlide>
          ))}
        </CarouselFree>
      );
    }

    return (
      <>
        {appsFiltered.map((item, itemIndex) => (
          <CardAppItem
            key={itemIndex}
            {...item}
            baseUrl={baseUrl}
            isSecondary
            showCategorization
          />
        ))}
      </>
    );
  };

  return (
    <section className="section section-home-especializadas">
      <div className="container">
        <Title
          component="h2"
          size="xl"
        >
          {i18n.gettext('Especializadas')}
        </Title>

        <Tabs
          srLabel={i18n.gettext('Especializadas')}
          className="custom-tabs"
        >
          {filtered.map((filteredItem, filteredItemIndex) => (
            <Tab
              id={`tab-${filteredItemIndex}`}
              title={filteredTitles[filteredItem]}
              className={`custom-tab custom-tab--${filteredItem.toLowerCase()}`}
              key={`custom-tab-${filteredItem}`}
            >
              <TabContent id={`tab-content-${filteredItemIndex}`}>
                {renderItems(content, filteredItem)}
              </TabContent>
            </Tab>
          ))}
        </Tabs>
      </div>
    </section>
  );
};

EspecializadasView.propTypes = {
  deviceType: PropTypes.string,
};

export default EspecializadasView;

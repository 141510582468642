import React from 'react';
import PropTypes from 'prop-types';

import { useI18n } from 'nordic/i18n';
import { Text } from '@andes/typography';
import { Pill } from '@andes/badge';
import PlatinumBadgeIcon from '../SVG/badges/icon-platinum-badge';
import GoldBadgeIcon from '../SVG/badges/icon-gold-badge';
import SilverBadgeIcon from '../SVG/badges/icon-silver-badge';
import CertifiedBadgeIcon from '../SVG/badges/icon-certified-badge';

const getBadgeIcon = (certificationLevel) => {
  let badge;
  switch (certificationLevel) {
    case 'platinum':
      badge = PlatinumBadgeIcon;
      break;
    case 'gold':
      badge = GoldBadgeIcon;
      break;
    case 'silver':
      badge = SilverBadgeIcon;
      break;
    default:
      badge = CertifiedBadgeIcon;
      break;
  }
  return badge;
};

const CertificationBadge = ({ type, level, businessType }) => {
  const { i18n } = useI18n();
  const BadgeIcon = getBadgeIcon(level);
  const isSecondary = type === 'secondary';
  const isOnlyCertified = level === 'certified' || level === 'Certificado' || level === 'Inhouse' || level === '';

  if (isSecondary) {
    if (isOnlyCertified) return null;

    return (
      <Pill className={`certification-badge certification-badge--pill certification-badge--pill-${level.toLowerCase()}`}>
        {level}
      </Pill>
    );
  }

  return (
    <div className="certification-badge">
      <BadgeIcon />
      <div className="certification-badge__description">
        <Text size="m">{!isOnlyCertified ? level : i18n.gettext('Certificadas')}</Text>
        <Text size="s" color="secondary">{!businessType ? i18n.gettext('Aplicación certificada') : i18n.gettext('Solución certificada')}</Text>
      </div>
    </div>
  );
};

CertificationBadge.propTypes = {
  type: PropTypes.string,
  level: PropTypes.string,
};

export default CertificationBadge;

import React from 'react';
import PropTypes from 'prop-types';

const IconAgree = () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99205 21.6695C8.09728 21.8094 8.21359 21.9437 8.34099 22.0711L11.5983 25.3285C12.7554 26.4855 14.4805 26.7284 15.876 26.0571L15.8827 26.0611C17.6338 27.1207 19.8819 26.8482 21.3292 25.401L25.0607 21.6695H27V24.6695H31.5V23.1695H28.5V9.66946L31.501 9.66946V8.16946H27V9.66946H23.5607L18.8912 5L9.936 9.66946H4.5V8.16946H0.001V9.66946H3V23.1695H0V24.6695H4.5V21.6695H7.99205ZM9.19737 11.1695H4.5V20.1695H7.31799C7.07597 18.9781 7.41697 17.6918 8.34099 16.7678L8.59835 16.5104C10.0628 15.046 12.4372 15.046 13.9017 16.5104L17.159 19.7678C18.6108 21.2196 18.6234 23.5658 17.1966 25.033C18.2515 25.4089 19.4522 25.1566 20.2685 24.3403L23.7354 20.8734L16.9752 12.7612L13.7025 13.5794C12.0341 13.9965 10.296 13.2254 9.48551 11.7087L9.19737 11.1695ZM27 20.1695V11.1695H22.9393L18.6088 6.83892L10.7685 10.927L10.8085 11.0018C11.2948 11.9118 12.3377 12.3745 13.3387 12.1242L17.5248 11.0777L25.1013 20.1695H27ZM12.659 24.2678L9.40165 21.0104C8.52297 20.1318 8.52297 18.7071 9.40165 17.8285L9.65901 17.5711C10.5377 16.6924 11.9623 16.6924 12.841 17.5711L16.0984 20.8285C16.977 21.7071 16.977 23.1318 16.0984 24.0104L15.841 24.2678C14.9623 25.1465 13.5377 25.1465 12.659 24.2678Z"
      fill="black"
      fillOpacity={0.55}
    />
  </svg>
);
IconAgree.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
IconAgree.defaultProps = {
  fill: '#737373',
  width: 32,
  height: 32,
  className: '',
};
export default (props) => <IconAgree {...props} />;

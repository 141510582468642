
import React from 'react';
import PropTypes from 'prop-types';

const IconBag = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 75 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Iconos/Home">
        <g id="Group 934">
          <g id="Iconos/Home/Iconos/Home/11/Home/07 Navegaci&#195;&#179;n/Property 69">
            <g id="07 Navegaci&#195;&#179;n / Purchases">
              <path id="Icon shape" className={className} d="M50.7797 20.6458H45.6569V18.8282C45.6569 14.3248 42.0061 10.674 37.5027 10.674C32.9993 10.674 29.3485 14.3248 29.3485 18.8282V20.6458H24.1789L18.4946 49.3125H56.5116L50.7797 20.6458ZM31.1402 25.1173V22.4375H43.8652V25.1173H45.6569V22.4375H49.3108L53.4326 43.0517H21.5626L25.6502 22.4375H29.3485V25.1173H31.1402ZM21.2074 44.8433L20.6764 47.5208H54.3262L53.7908 44.8433H21.2074ZM43.8652 18.8282V20.6458H31.1402V18.8282C31.1402 15.3143 33.9888 12.4657 37.5027 12.4657C41.0166 12.4657 43.8652 15.3143 43.8652 18.8282Z" fill={fill} stroke="#3483FA" strokeWidth="1.5" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconBag.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconBag.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default (props) => <IconBag {...props} />;

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@andes/card';
import { Title, Text } from '@andes/typography';
import { useI18n } from 'nordic/i18n';
import StageIcon from '../../../../components/SVG/stages';
import { isMobile, getFormattedUrlPropsCategory, getUrlWithParams } from '../../../../../utils';

const renderCategories = (categories, deviceType, baseUrl, pageType = 'home') => categories.map(stage => (
  <Card className="section-home-categories__item" key={stage.url}>
    <a
      {...getFormattedUrlPropsCategory(baseUrl, getUrlWithParams(stage.url, { utm_source: `${pageType}/section-categories` }))}
      className={!isMobile(deviceType) ? 'section-home-categories__item__link' : 'section-home-categories__item__link section-home-categories__item__link--hover'}
      title={stage.name}
    >
      <StageIcon
        id={stage.id}
        className="icon-fill"
        fill="#3483FA"
      />
      <div>
        <div className={`section-home-categories__item__bg section-home-categories__item__bg--${stage.url}`} />
        <Title
          component="h3"
          size="xs"
        >
          {stage.name}
        </Title>
        <Text size="s" color="inverted">{stage.description}</Text>

        <div className="section-home-categories__item__bus">
          {stage.mlibre && <div className="logo-bu logo-bu--mlibre" />}
          {stage.mpago && <div className="logo-bu logo-bu--mpago" />}
        </div>
      </div>
    </a>
  </Card>
));

const StagesView = ({ className, deviceType, stages, baseUrl, pageType }) => {
  const { i18n } = useI18n();

  if (stages.length === 0) return null;

  return (
    <section className={`section section-home-categories ${className}`}>
      <div className="container">
        <div className="section-home-categories__header">
          <Title component="h2" size="xl">
            {i18n.gettext('Soluciones para tu negocio')}
          </Title>
          <Text component="p" size="m">
            {i18n.gettext('Explora todas las opciones que tenemos para potenciar tu negocio')}
          </Text>
        </div>
        <div className="section-home-categories__items">
          {renderCategories(stages, deviceType, baseUrl, pageType)}
        </div>
      </div>
    </section>
  );
};

StagesView.propTypes = {
  deviceType: PropTypes.string,
  stages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    sites: PropTypes.arrayOf(PropTypes.string),
    sf_id: PropTypes.number,
    mlibre: PropTypes.bool,
    mshops: PropTypes.bool,
  })),
  redirectUrl: PropTypes.string,
  pageType: PropTypes.string,
};

StagesView.defaultProps = {
  className: '',
};

export default StagesView;

/* eslint-disable security/detect-object-injection */
import React from 'react';

const FooterView = (props) => {
  const { mlNavigationConfigItems } = props;

  const date = new Date();

  const copyRightMessage = `Copyright © ${date.getFullYear()} ${mlNavigationConfigItems.mlNavigationCompanyName}`;

  return (
    <footer className="nav-footer">
      <div className="nav-footer-copyright">
        <small>{copyRightMessage}</small>
      </div>
      <div className="nav-footer-navigation">
        <ul>
          <li>
            <a href={mlNavigationConfigItems.mlNavigationTerminos.link}>
              {mlNavigationConfigItems.mlNavigationTerminos.name}
            </a>
          </li>
          <li>
            <a href={mlNavigationConfigItems.mlNavigationPolitics.link}>
              {mlNavigationConfigItems.mlNavigationPolitics.name}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

FooterView.propTypes = {};

export default FooterView;

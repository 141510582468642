import React from 'react';
import PropTypes from 'prop-types';
import { Title, Text } from '@andes/typography';
import { useI18n } from 'nordic/i18n';

const IMAGE_DESKTOP = 'https://http2.mlstatic.com/storage/developers-site-cms-admin/CDP/242896756673-banner-principal-cdp.png';
const IMAGE_MOBILE = 'https://http2.mlstatic.com/storage/developers-site-cms-admin/248252055825-Banner.jpg';

const BannerView = ({ isMobile }) => {
  const { i18n } = useI18n();
  const imageUrl = !isMobile ? IMAGE_DESKTOP : IMAGE_MOBILE;
  const descriptionSize = !isMobile ? 'l' : 's';

  return (
    <section
      className="section section-home-banner"
      style={{
        backgroundImage: ` url('${imageUrl}')`,
      }}
    >
      <div className="container">
        <div className="section-home-banner__text">
          <Title component="h2" size="l" color="inverted">
            {i18n.gettext('Soluciones que potencian tu negocio.')}
          </Title>
          <Text component="p" size={descriptionSize} color="inverted">
            {i18n.gettext('Encontrá la opción que más se adapte a tu negocio e incrementá tus ventas.')}
          </Text>
        </div>
      </div>
    </section>
  );
};

BannerView.propTypes = {
  isMobile: PropTypes.bool,
};

export default BannerView;

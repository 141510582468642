
import React from 'react';
import PropTypes from 'prop-types';

const IconEcommerce = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 86 86"
      fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M59.814 23H25.8917L20 34.6819V34.998C20 37.7408 21.6656 40.0866 24.0356 41.1107V63H61.6698V41.1108C64.04 40.0869 65.7058 37.7409 65.7058 34.998V34.6819L59.814 23ZM33.4193 34.5102H30.7621V34.998C30.7621 37.1999 28.9584 39.0041 26.7097 39.0041C24.5622 39.0041 22.8201 37.3583 22.668 35.2926L27.5276 25.6572H58.1782L63.0378 35.2926C62.8857 37.3583 61.1436 39.0041 58.9961 39.0041C56.7473 39.0041 54.9436 37.1999 54.9436 34.998V34.5102H52.2864V34.998C52.2864 37.1999 50.4827 39.0041 48.234 39.0041C45.9852 39.0041 44.1815 37.1999 44.1815 34.998V34.5102H41.5243V34.998C41.5243 37.1999 39.7206 39.0041 37.4718 39.0041C35.2231 39.0041 33.4193 37.1999 33.4193 34.998V34.5102ZM58.9961 41.6612L59.0126 41.6612V60.3428H26.6928V41.6612L26.7097 41.6612C28.9091 41.6612 30.8662 40.6084 32.0907 38.9791C33.3153 40.6084 35.2724 41.6612 37.4718 41.6612C39.6712 41.6612 41.6284 40.6084 42.8529 38.9791C44.0774 40.6084 46.0345 41.6612 48.234 41.6612C50.4334 41.6612 52.3905 40.6084 53.615 38.9791C54.8396 40.6084 56.7967 41.6612 58.9961 41.6612Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M48.2172 45.6724H38.3292V48.3296H43.6614L36.1899 55.7366L38.0607 57.6236L45.56 50.189V55.4908H48.2172V45.6724Z" className={className} fill={fill} />
    </svg>
  );
};

IconEcommerce.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconEcommerce.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconEcommerce {...props} />;

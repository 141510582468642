import React from 'react';
import PropTypes from 'prop-types';

const IconCamera = () => (
  <svg
    width="35"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5005 9.75015H7.4995V8.25015H4.5005V9.75015Z"
      fill="black"
      fillOpacity={0.55}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2214 17.2501C17.1209 17.2501 19.4714 14.8996 19.4714 12.0001C19.4714 9.10065 17.1209 6.75015 14.2214 6.75015C11.322 6.75015 8.97145 9.10065 8.97145 12.0001C8.97145 14.8996 11.322 17.2501 14.2214 17.2501ZM14.2214 15.7501C12.1504 15.7501 10.4714 14.0712 10.4714 12.0001C10.4714 9.92908 12.1504 8.25015 14.2214 8.25015C16.2925 8.25015 17.9714 9.92908 17.9714 12.0001C17.9714 14.0712 16.2925 15.7501 14.2214 15.7501Z"
      fill="black"
      fillOpacity={0.55}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4039 2.97949L19.9009 5.25015H23.25V18.0001C23.25 19.2428 22.2426 20.2501 21 20.2501H3C1.75736 20.2501 0.75 19.2428 0.75 18.0001V7.50015C0.75 6.2575 1.75736 5.25015 3 5.25015H8.59688L10.0989 2.97949H18.4039ZM17.5961 4.47949H10.9051L9.40312 6.75015H3C2.58579 6.75015 2.25 7.08593 2.25 7.50015V18.0001C2.25 18.4144 2.58579 18.7501 3 18.7501H21C21.4142 18.7501 21.75 18.4144 21.75 18.0001V6.75015H19.0931L17.5961 4.47949Z"
      fill="black"
      fillOpacity={0.55}
    />
  </svg>
);
IconCamera.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
IconCamera.defaultProps = {
  fill: '#737373',
  width: 35,
  height: 24,
  className: '',
};
export default (props) => <IconCamera {...props} />;


import React from 'react';
import PropTypes from 'prop-types';

const IconFacePayment = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 39 32" fill="none">
      <path className={className} fillRule="evenodd" clipRule="evenodd" d="M10.928 5.03906H24.2299C24.9442 5.03906 25.5232 5.62233 25.5232 6.34183V9.40431H27.9053C29.0277 9.40431 29.9376 10.3209 29.9376 11.4515V20.3848C29.9376 21.5154 29.0277 22.432 27.9053 22.432H25.5232V25.6973C25.5232 26.4168 24.9442 27 24.2299 27H10.928C10.2138 27 9.63477 26.4168 9.63477 25.6973V6.34183C9.63477 5.62233 10.2138 5.03906 10.928 5.03906ZM25.5232 10.5206V12.8557H28.8291V11.4516C28.8291 10.9377 28.4154 10.5211 27.9051 10.5211L25.5232 10.5206ZM25.5232 21.315V13.9723H28.8291V20.3849C28.8291 20.8988 28.4154 21.3154 27.9051 21.3154L25.5232 21.315ZM24.4132 6.34181V9.40431H24.3951V22.432H24.4132V25.6973C24.4132 25.8 24.3305 25.8834 24.2285 25.8834H10.9266C10.8245 25.8834 10.7418 25.8 10.7418 25.6973V6.34181C10.7418 6.23903 10.8245 6.1557 10.9266 6.1557H24.2285C24.3305 6.1557 24.4132 6.23903 24.4132 6.34181ZM12.2635 11.1974H22.9789V15.5361H12.2635V11.1974ZM21.8688 14.4188V12.3136H13.3703V14.4188H21.8688ZM15.0313 9.54515V8.42867H12.8142V9.54515H15.0313ZM18.7264 19.2228V18.1063H16.5093V19.2228H18.7264ZM15.0313 19.2228V18.1063H12.8142V19.2228H15.0313ZM22.4245 18.1063V19.2228H20.2074V18.1063H22.4245ZM15.0313 22.4798V21.3633H12.8142V22.4798H15.0313ZM18.7264 21.3633V22.4798H16.5093V21.3633H18.7264ZM22.4245 22.4798V21.3633H20.2074V22.4798H22.4245Z" fill={fill} />
    </svg>
  );
};

IconFacePayment.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconFacePayment.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconFacePayment {...props} />;

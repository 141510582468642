/* eslint-disable no-script-url */
/* eslint-disable nordic/no-img-tags */
import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from 'nordic/i18n';
import { Image } from 'nordic/image';
import { Pill } from '@andes/badge';
import Card, {
  CardSecondary,
  CardContent,
} from '@andes/card';
import Thumbnail from '@andes/thumbnail';
import { Text } from '@andes/typography';
import CertificationBadge from '../CertificationBadge';

const CardAppItem = (props) => {
  const {
    baseUrl,
    isSecondary = false,
    categories,
    showCategorization = false,
    showBus = false,
    url,
    logo_url: logoUrl,
    name,
    main_category_name: mainCategoryName,
    description,
    short_description: shortDescription,
    certification_level: certificationLevel,
    recommended,
    entity_type: entityType,
  } = props;

  const { i18n } = useI18n();

  const CardComponent = !isSecondary ? Card : CardSecondary;

  const descriptionText = shortDescription || description;

  const isMPagoApp = categories?.some(category => category.mpago);

  const isExternalLink = url?.startsWith('http');
  const hrefProps = {
    title: name,
    ...(url && {
      href: !isExternalLink ? `${baseUrl}${url}` : url,
      target: !isExternalLink ? '_self' : '_blank',
    }),
  };

  return (
    <CardComponent shadow="outline" className="card-app-item">
      <CardContent className="card-app-item__wrapper">
        <a {...hrefProps}>
          <div className="card-app-item__header">
            <div className="card-app-item__main-title">
              {logoUrl && (
                <Thumbnail
                  modifier="square"
                  size="72"
                  className="andes-card--flat"
                >
                  <Image
                    src={logoUrl}
                    alt={name}
                    lazyload="off"
                  />
                </Thumbnail>
              )}
              <div className="card-app-item__main-texts">
                <Text component="h2" size="s">
                  {name}
                </Text>
                <Text
                  color="secondary"
                  size="xs"
                >
                  {(entityType === 'app') && i18n.gettext('Aplicación')}
                  {(entityType === 'service') && i18n.gettext('Servicio')}
                  {(entityType === 'tool') && i18n.gettext('Herramienta')}
                  {(entityType === 'agency') && i18n.gettext('Agencia')}
                  {(entityType === 'developer') && i18n.gettext('Profesional de desarrollo')}
                </Text>
              </div>
            </div>
            {showCategorization && (
              <div className="card-app-item__main-category">
                <Text
                  color="link"
                  size="xs"
                  href="https://mercadolibre.com"
                >
                  {mainCategoryName}
                </Text>
              </div>
            )}
          </div>

          <div className="card-app-item__content">
            {descriptionText && (
              <Text size="xs">
                {descriptionText}
              </Text>
            )}
          </div>

          <div className="card-app-item__footer">
            {recommended ? (
              <div className="card-app-item__footer-recommended-badge">
                <Pill
                  srLabel={i18n.gettext('Recomendada')}
                  color="gray"
                >
                  {i18n.gettext('Recomendada')}
                </Pill>
              </div>
            ) : (
              <CertificationBadge
                type="secondary"
                level={certificationLevel}
              />)}
            {showBus && (
              <div className="logo-bus">
                {isMPagoApp && <div className="logo-bu logo-bu--mpago" />}
              </div>
            )}
          </div>
        </a>
      </CardContent>
    </CardComponent>
  );
};

CardAppItem.propTypes = {
  isSecondary: PropTypes.bool,
  showCategorization: PropTypes.bool,
  showBus: PropTypes.bool,
  url: PropTypes.string,
  logo_url: PropTypes.string,
  name: PropTypes.string,
  main_category_name: PropTypes.string,
  short_description: PropTypes.string,
  certification_level: PropTypes.string,
  recommended: PropTypes.bool,
  entityType: PropTypes.string,
};

export default CardAppItem;


import React from 'react';
import PropTypes from 'prop-types';

const IconEducation = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 86 86" fill="none">
      <path d="M42.4284 23L73.8568 38.8828L63.629 44.0516V54.6964L45.5633 63H39.2927L21.2271 54.6964V44.0512L18.4788 42.6623L18.4788 53.6001C19.7504 54.1569 20.6218 55.4334 20.6218 56.8868V59.4164C20.6218 61.3675 19.0512 63.0001 17.0504 63.0001C15.0496 63.0001 13.479 61.3675 13.479 59.4164V56.8868C13.479 55.4336 14.3503 54.1571 15.6217 53.6002L15.6217 41.2185L11 38.8828L42.4284 23ZM17.3209 38.8828L42.4284 51.5713L67.5359 38.8828L42.4284 26.1944L17.3209 38.8828ZM24.0538 46.1584V52.8773L39.9097 60.1652H44.9463L60.8022 52.8773V46.1584L42.428 55.3715L24.0538 46.1584ZM17.0504 55.7725C16.487 55.7725 15.979 56.2433 15.979 56.8868V59.4164C15.979 60.0599 16.487 60.5307 17.0504 60.5307C17.6138 60.5307 18.1218 60.0599 18.1218 59.4164V56.8868C18.1218 56.2433 17.6138 55.7725 17.0504 55.7725Z" className={className} fill={fill} />
    </svg>
  );
};

IconEducation.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconEducation.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconEducation {...props} />;

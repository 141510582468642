
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Card,
  CardContent,
  CardFooter,
} from '@andes/card';
import { Title, Text } from '@andes/typography';
import { Image } from 'nordic/image';
import { useI18n } from 'nordic/i18n';

const getTexts = i18n => ({
  certificationPrograms: [
    {
      image: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/198470749199-certification-program-4.jpg',
      title: i18n.gettext('Postúlate para certificar tu aplicación de Mercado Shops'),
      description: i18n.gettext('Completa el formulario y comienza el proceso de certificación.'),
      cta: i18n.gettext('Ir al proceso de certificación'),
      urlId: 'mlNavigationCertifiedFormMercadoShops',
      excludeCountries: [],
    },
    {
      image: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/248336841528-certification-program-1.jpg',
      title: i18n.gettext('Ser un Experto Certificado'),
      description: i18n.gettext('Certificación para brindar asesoramiento integral a vendedores para hacer crecer sus negocios.'),
      cta: i18n.gettext('Ir a Expertos Certificados'),
      urlId: 'mlNavigationPartners',
      excludeCountries: ['MPE', 'MLV'],
    },
    {
      image: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/243496954647-certification-program-2.jpg',
      title: i18n.gettext('Súmate al Dev Program de Mercado Pago'),
      description: i18n.gettext('Certificación para ser parte de la comunidad Mercado Pago y acceder a beneficios extras.'),
      cta: i18n.gettext('Ir al Dev Program'),
      urlId: 'mpNavigationDeveloperPartnerProgram',
      excludeCountries: [],
    },
    {
      image: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/243496954647-certification-program-3.jpg',
      title: i18n.gettext('Conoce el Developer Partner Program'),
      description: i18n.gettext('Certificación para ser parte de la comunidad de Mercado Libre.'),
      cta: i18n.gettext('Ir al Developer Partner Program'),
      urlId: 'mlNavigationDeveloperPartnerProgram',
      excludeCountries: [],
    },
  ],
});

const CertificationProgramsView = ({ mlNavigationConfigItems, siteId }) => {
  const { i18n } = useI18n();
  const { certificationPrograms } = getTexts(i18n);

  const certificationProgramsFiltered = certificationPrograms.filter(certificationProgram => !certificationProgram.excludeCountries.includes(siteId));

  return (
    <section className="section section-home-certification">
      <div className="container">
        <div className="section-home-certification__header">
          <Title component="h2" size="xl">{i18n.gettext('Nuestros Programas de Certificaciones')}</Title>
          <Text component="p" size="m" color="secondary">
            {i18n.gettext('Estos son los programas de certificación con los que contamos en Mercado Libre.')}
          </Text>
        </div>
        <div className="section-home-certification__items">
          <>
            {certificationProgramsFiltered.map((certificationProgram, certificationProgramIndex) => (
              <Card className="notice-card" key={certificationProgramIndex}>
                <a
                  href={`${mlNavigationConfigItems[certificationProgram.urlId].link}?utm_source=centrodepartners`}
                  title={i18n.gettext(certificationProgram.title)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="notice-card__header">
                    <Image
                      src={certificationProgram.image}
                      alt={i18n.gettext(certificationProgram.title)}
                      className="notice-card__header__img"
                    />
                  </div>
                  <CardContent className="notice-card__content">
                    <Title component="h3" size="xs" className="notice-card__title">
                      {i18n.gettext(certificationProgram.title)}
                    </Title>
                    <Text component="p" size="m" color="secondary" className="notice-card__description">
                      {i18n.gettext(certificationProgram.description)}
                    </Text>
                  </CardContent>
                  <CardFooter className="notice-card__footer" showBorder={false}>
                    <Text
                      component="span"
                      color="link"
                      size="xs"
                    >
                      {i18n.gettext(certificationProgram.cta)}
                    </Text>
                  </CardFooter>
                </a>
              </Card>
            ))}
          </>
        </div>
      </div>
    </section>
  );
};

CertificationProgramsView.propTypes = {};

export default CertificationProgramsView;


import React from 'react';
import PropTypes from 'prop-types';

import IconCar from './icon-car';
import IconCurrencyTag from './icon-currency-tag';
import IconEcommerce from './icon-ecommerce';
import IconGraph from './icon-graph';
import IconHeart from './icon-heart';
import IconInmobile from './icon-inmobile';
import IconMegaphone from './icon-megaphone';
import IconPointer from './icon-pointer';
import IconReport from './icon-report';
import IconTruck from './icon-truck';
import IconFinances from './icon-finances';
import IconEducation from './icon-education';
import IconShakeHands from './icon-shake-hands';
import IconPayment from './icon-payment';
import IconOnlinePayment from './icon-online-payment';
import IconFacePayment from './icon-face-payment';
import IconBag from './icon-bag';
import IconFlashlight from './icon-flashlight';
import IconAgree from './icon-agree';
import IconUser from './icon-user';
import IconLaptop from './icon-laptop';
import IconCamera from './icon-camera';

const getIconComponent = (id, restProps) => {
  switch (id) {
    case 1:
    case 2:
      return <IconCurrencyTag {...restProps} />;
    case 3:
    case 4:
      return <IconHeart {...restProps} />;
    case 16:
      return <IconCamera {...restProps} />;
    case 5:
      return <IconGraph {...restProps} />;
    case 28:
    case 29:
      return <IconEcommerce {...restProps} />;
    case 15:
    case 6:
      return <IconMegaphone {...restProps} />;
    case 18:
      return <IconReport {...restProps} />;
    case 21:
    case 24:
      return <IconCar {...restProps} />;
    case 22:
    case 23:
      return <IconInmobile {...restProps} />;
    case 7:
    case 8:
      return <IconTruck {...restProps} />;
    case 30:
    case 35:
      return <IconFinances {...restProps} />;
    case 31:
    case 32:
      return <IconEducation {...restProps} />;
    case 33:
    case 34:
      return <IconShakeHands {...restProps} />;
    case 50:
    case 51:
      return <IconPayment {...restProps} />;
    case 39:
    case 41:
      return <IconOnlinePayment {...restProps} />;
    case 40:
    case 42:
      return <IconFacePayment {...restProps} />;
    case 52:
    case 53:
      return <IconBag {...restProps} />;
    case 59:
    case 60:
      return <IconFlashlight {...restProps} />;
    case 9:
    case 10:
      return <IconAgree {...restProps} />;
    case 11:
    case 12:
      return <IconUser {...restProps} />;
    case 13:
    case 14:
    case 17:
      return <IconLaptop {...restProps} />;
    default:
      return <IconPointer {...restProps} />;
  }
};

const Stage = (props) => {
  const { id, ...restProps } = props;
  const IconComponent = getIconComponent(id, restProps);

  return (
    <>
      {IconComponent || <IconPointer {...restProps} />}
    </>
  );
};

Stage.propTypes = {
  id: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string,
};

Stage.defaultProps = {
  id: 1,
  width: 86,
  height: 86,
  fill: '#3483FA',
  className: '',
};

export default props => <Stage {...props} />;


import React from 'react';
import PropTypes from 'prop-types';

const IconHeart = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <path
          fill={fill}
          className={className}
          fillRule="nonzero"
          d="M16.831 35.418c2.13 1.006 4.573 1.578 7.169 1.578 8.284 0 15-5.82 15-12.998C39 16.819 32.284 11 24 11c-8.284 0-15 5.82-15 12.998 0 3.345 1.458 6.395 3.854 8.699l-.732 3.663 4.71-.942zm-5.984 2.217l.921-4.61C9.357 30.529 8 27.359 8 23.999 8 16.233 15.193 10 24 10s16 6.233 16 13.998-7.193 13.998-16 13.998c-2.57 0-5.055-.53-7.291-1.533l-5.862 1.172z"
        />
        <path
          fill={fill}
          className={className}
          fillRule="nonzero"
          d="M30.378 18.479a4.838 4.838 0 0 1 0 6.841L23.9 31.8l-6.478-6.479a4.838 4.838 0 0 1 6.478-7.17 4.839 4.839 0 0 1 6.48.329zm-.725 6.116a3.813 3.813 0 0 0-5.391-5.391l-.363.362-.362-.362a3.813 3.813 0 0 0-5.391 5.391l5.753 5.754 5.754-5.754z"
        />
      </g>
    </svg>
  );
};

IconHeart.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconHeart.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconHeart {...props} />;


import React from 'react';
import PropTypes from 'prop-types';

const IconGraph = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <path
          fill={fill}
          className={className}
          fillRule="nonzero"
          d="M35 22c0-7.18-5.82-13-13-13S9 14.82 9 22s5.82 13 13 13V22h13zm-12 1v13h-1c-7.732 0-14-6.268-14-14S14.268 8 22 8s14 6.268 14 14v1H23z"
        />
        <path
          fill={fill}
          className={className}
          fillRule="nonzero"
          d="M27 39c6.627 0 12-5.373 12-12H27v12zm-1 1V26h14v1c0 7.18-5.82 13-13 13h-1z"
        />
      </g>
    </svg>
  );
};

IconGraph.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconGraph.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconGraph {...props} />;


import React from 'react';
import PropTypes from 'prop-types';

const IconPointer = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 86 86" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.2915 36.1768H69.2221V53.7184H28.2915V36.1768ZM31.0092 38.8945V51.0007H66.5043V38.8945H31.0092Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.6455 29.5884H62.5761V32.3061H24.3632V47.13H21.6455V29.5884Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M15 23H55.9305V25.7177H17.7177V40.5417H15V23Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M51.2492 60.5951L56.2321 55.6521L61.2173 50.7115L44.603 44.124L51.2492 60.5951Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42.1567 41.6921L63.65 50.2159L57.1894 56.6178L50.7547 63.0001L42.1567 41.6921ZM47.0501 46.5564L51.7446 58.1905L58.7854 51.2104L47.0501 46.5564Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M56.2249 53.7324L63.1392 60.5871L61.2258 62.5171L54.3115 55.6624L56.2249 53.7324Z" className={className} fill={fill} />
    </svg>
  );
};

IconPointer.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconPointer.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default (props) => <IconPointer {...props} />;

import React, { useState, useCallback } from 'react';
import CarouselSnapped, { CarouselSnappedSlide } from '@andes/carousel-snapped';
import { Title, Text } from '@andes/typography';
import Button from '@andes/button';
import { Image } from 'nordic/image';
import PropTypes from 'prop-types';
import { useI18n } from 'nordic/i18n';
import { isMobile } from '../../../../../utils';

const getTexts = i18n => ({
  slides: [
    {
      image: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/248349911028-banner-test-1.png',
      title: i18n.gettext('Conoce nuestro Centro de Vendedores'),
      subtitle: i18n.gettext('Si eres un nuevo vendedor, ¡éstas recomendaciones son para ti.'),
      description: i18n.gettext('Entérate todo lo que tienes que saber para potenciar tus ventas.'),
      button: i18n.gettext('Ir al Centro de Vendedores'),
      classModifier: 'section-home-recommendations__area--mp',
      urlId: 'mlNavigationSellerCentral',
      excludeCountries: [],
    },
    {
      image: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/248265194955-banner-test-2.png',
      title: i18n.gettext('Activa tu cuenta en Mercado Shops'),
      subtitle: i18n.gettext('Si eres un nuevo vendedor, ¡éstas recomendaciones son para ti.'),
      description: i18n.gettext('Impulsa tu crecimiento y mejora la experiencia de compra.'),
      button: i18n.gettext('Activa tu cuenta'),
      classModifier: 'section-home-recommendations__area--ms',
      urlId: 'mlNavigationMercadoShops',
      excludeCountries: ['MLU', 'MPE'],
    },
  ],
});

const RecommendationNewSellerView = ({ deviceType, mlNavigationConfigItems, siteId }) => {
  const { i18n } = useI18n();
  const { slides } = getTexts(i18n);

  const slidesFiltered = slides.filter(slide => !slide.excludeCountries.includes(siteId));

  const [currentSlide, setCurrentSlide] = useState(slides[0]);

  const handleAfterChange = useCallback((newIndex) => {
    setCurrentSlide(slides[`${newIndex}`]);
  }, [slides]);

  let carouselSnappedProps = {
    srLabel: i18n.gettext('Recomendaciones'),
    pagination: false,
    afterChange: handleAfterChange,
  };

  if (isMobile(deviceType)) {
    carouselSnappedProps = {
      arrows: false,
      pagination: true,
      mobile: true,
      type: 'full',
      scroll: 'visible',
    };
  }

  const carouselDeviceTypeCondition = () => (isMobile(deviceType) ? (
    <>
      <CarouselSnapped srLabel="Carousel" {...carouselSnappedProps}>
        {slides.map((slide) => (
          <CarouselSnappedSlide key={slide.classModifier}>
            <Image
              src={slide.image}
              alt={slide.title}
              lazyload="off"
            />
          </CarouselSnappedSlide>
        ))}
      </CarouselSnapped>
      <div className="section-home-recommendations__content-controls">
        <Button
          fullWidth
          href={`${mlNavigationConfigItems[currentSlide.urlId].link}?utm_source=centrodepartners`}
          target="_blank"
        >
          {currentSlide.button}
        </Button>
      </div>
    </>
  ) : (
    <CarouselSnapped {...carouselSnappedProps}>
      {slides.map((slide) => (
        <CarouselSnappedSlide key={slide.classModifier}>
          <Image
            src={slide.image}
            alt={slide.title}
            lazyload="off"
          />
        </CarouselSnappedSlide>
      ))}
    </CarouselSnapped>
  )
  );

  return (
    <section className="section section-home-recommendations">
      <div className="container">
        <div
          className={`section-home-recommendations__area ${currentSlide.classModifier}`}
        >
          <div className="section-home-recommendations__content">
            <Title
              component="h2"
              size="xl"
            >
              {currentSlide.title}
            </Title>
            <Text
              component="span"
              color="secondary"
              size="m"
            >
              {currentSlide.subtitle}
            </Text>
            <Text
              component="p"
              size="l"
            >
              {currentSlide.description}
            </Text>
            <div className={isMobile(deviceType) ? 'section-home-recommendations__content-controls-none' : 'section-home-recommendations__content-controls'}>
              <Button
                fullWidth
                href={`${mlNavigationConfigItems[currentSlide.urlId].link}?utm_source=centrodepartners`}
                target="_blank"
              >
                {currentSlide.button}
              </Button>
            </div>
          </div>
          <div className="section-home-recommendations__slider">
            {(slidesFiltered.length > 1) ? (
              carouselDeviceTypeCondition()
            ) : (
              <div>
                <Image
                  src={currentSlide.image}
                  alt={currentSlide.title}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

RecommendationNewSellerView.propTypes = {
  deviceType: PropTypes.string,
};

export default RecommendationNewSellerView;

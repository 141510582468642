import '../pages/home/home.scss';

import './polyfills';

import React from 'react';
import { hydrateRoot } from 'nordic/hydrate';
import I18n from 'nordic/i18n';
import { AndesProvider } from '@andes/context';
import I18nProvider from 'nordic/i18n/I18nProvider';
import HomeView from '../pages/home/view';

const preloadedState = window.__PRELOADED_STATE__;

const i18n = new I18n({
  translations: preloadedState.translations,
});

hydrateRoot(
  document.getElementById('root-app'),
  <AndesProvider
    locale={preloadedState.locale}
    device={preloadedState.device}
    csrfToken={preloadedState.csrfToken}
  >
    <I18nProvider i18n={i18n}>
      <HomeView {...preloadedState} />
    </I18nProvider>
  </AndesProvider>,
);

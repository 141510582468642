import React from 'react';
import PropTypes from 'prop-types';
import Head from 'nordic/head';
import { useI18n } from 'nordic/i18n';

const HeadView = props => {
  const { i18n } = useI18n();
  const {
    pageTitle = 'Centro de Partners',
    pageDescription,
    linkCanonical,
    noIndex,
  } = props;

  const title = `${i18n.gettext(pageTitle)}`;
  const description = pageDescription || i18n.gettext('Todas las soluciones para hacer crecer tu negocio, en un solo lugar.');
  const imgTagContent = 'https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/202292706847-Thumbnail--3-.png';

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="author" content="Open Platform Mercado Libre" />
      <meta httpEquiv="cleartype" content="on" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

      {/* <!-- Schema.org markup for Google+ --> */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={imgTagContent} />

      {/* <!-- Twitter Card data --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@MercadoLivre" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgTagContent} />

      {/* <!-- Open Graph data --> */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://centrodepartners.mercadolibre.com" />
      <meta property="og:image:secure_url" content={imgTagContent} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />

      {/* <!-- Google Search Console SEO Optimization --> */}
      { !!linkCanonical && <link rel="canonical" href={linkCanonical} /> }
      { noIndex && <meta name="robots" content="noindex, nofollow" /> }

      <link rel="preconnect" href="https://http2.mlstatic.com" />
      <link rel="dns-prefetch" href="https://http2.mlstatic.com" />
      <link
        rel="icon"
        type="image/png"
        href="https://http2.mlstatic.com/ui/navigation/3.0.8/mercadolibre/120x120-precomposed.png"
        sizes="120x120"
      />

      {/* <!-- The HTML5 Shiv (https://github.com/aFarkas/html5shiv) -->
              <!--[if lt IE 9]> */}
      {/* <![endif]--> */}

      <link href="https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/300-400-600-700.css" rel="stylesheet" />
    </Head>
  );
};

HeadView.propTypes = {
  linkCanonical: PropTypes.string,
  noIndex: PropTypes.bool,
};

HeadView.defaultProps = {
  noIndex: false,
};

export default HeadView;


import React from 'react';
import PropTypes from 'prop-types';

const IconCar = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 86 86" fill="none">
      <path d="M29.0982 61.0698C28.835 61.0698 28.6217 61.2865 28.6217 61.5537V61.0698H29.0982ZM28.6217 56.2224H30.0513V61.5537C30.0513 62.0882 29.6246 62.5215 29.0982 62.5215H20.8706C20.3442 62.5215 19.9175 62.0882 19.9175 61.5537V54.7708L21.3471 54.9755V61.0698H28.6217V56.2224ZM21.3471 61.0698V61.5537C21.3471 61.2865 21.1338 61.0698 20.8706 61.0698H21.3471ZM21.3471 61.5537C21.3471 61.2865 21.1338 61.0698 20.8706 61.0698H21.3471V61.5537ZM21.3471 61.0698H28.6217V56.2224H30.0513V61.5537C30.0513 62.0882 29.6246 62.5215 29.0982 62.5215H20.8706C20.3442 62.5215 19.9175 62.0882 19.9175 61.5537V54.7708L21.3471 54.9755V61.0698ZM29.0982 61.0698C28.835 61.0698 28.6217 61.2865 28.6217 61.5537V61.0698H29.0982Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.439 54.2192L21.8252 54.5611V60.5917H28.1431V55.7443H30.5294V61.5539C30.5294 62.3457 29.8954 63 29.098 63H20.8704C20.0729 63 19.439 62.3457 19.439 61.5539V54.2192ZM28.6215 61.07H21.3469V54.9757L19.9173 54.7709V61.5539C19.9173 62.0884 20.344 62.5217 20.8704 62.5217H29.098C29.6244 62.5217 30.0511 62.0884 30.0511 61.5539V56.2226H28.6215V61.07Z" className={className} fill={fill} />
      <path d="M63.1744 61.0683C62.9112 61.0683 62.6978 61.2849 62.6978 61.5522V61.0683H63.1744ZM62.6978 54.725L64.1274 54.479V61.5522C64.1274 62.0867 63.7007 62.52 63.1744 62.52H54.9467C54.4204 62.52 53.9937 62.0867 53.9937 61.5522V56.2227H55.4233V61.0683H62.6978V54.725ZM55.4233 61.5522C55.4233 61.2849 55.2099 61.0683 54.9467 61.0683H55.4233V61.5522ZM55.4233 61.5522C55.4233 61.2849 55.2099 61.0683 54.9467 61.0683H55.4233V61.5522ZM62.6978 61.0683V54.725L64.1274 54.479V61.5522C64.1274 62.0867 63.7007 62.52 63.1744 62.52H54.9467C54.4204 62.52 53.9937 62.0867 53.9937 61.5522V56.2227H55.4233V61.0683H62.6978ZM63.1744 61.0683C62.9112 61.0683 62.6978 61.2849 62.6978 61.5522V61.0683H63.1744Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M62.2193 54.3217L64.6055 53.9111V61.5519C64.6055 62.3437 63.9716 62.998 63.1742 62.998H54.9465C54.1491 62.998 53.5151 62.3437 53.5151 61.5519V55.7442H55.9014V60.5897H62.2193V54.3217ZM62.6976 61.0681H55.4231V56.2225H53.9934V61.5519C53.9934 62.0864 54.4202 62.5197 54.9465 62.5197H63.1742C63.7005 62.5197 64.1272 62.0864 64.1272 61.5519V54.4788L62.6976 54.7248V61.0681Z" className={className} fill={fill} />
      <path d="M60.9115 38.3162H59.8046L55.8047 27.1586C55.3251 25.8205 54.0725 24.93 52.6702 24.93H31.4852C30.0829 24.93 28.8303 25.8205 28.3507 27.1586L24.3509 38.3162H23.2439C21.4016 38.3162 19.9081 39.8328 19.9081 41.7035V51.068C19.9081 53.0702 21.4118 54.7421 23.3755 54.9233C29.554 55.4933 35.7329 55.7783 41.9125 55.7783C48.1295 55.7783 54.4167 55.4899 60.7743 54.9129C62.7404 54.7344 64.2473 53.0615 64.2473 51.057L64.2473 41.7035C64.2473 39.8328 62.7538 38.3162 60.9115 38.3162ZM60.8055 36.8645C63.5433 36.8645 65.6769 39.031 65.6769 41.7035L65.6769 51.057C65.6769 53.8132 63.605 56.1134 60.9016 56.3588C54.5019 56.9396 48.1722 57.23 41.9125 57.23C35.6897 57.23 29.4676 56.943 23.2462 56.369C20.546 56.1199 18.4785 53.8211 18.4785 51.068V41.7035C18.4785 39.031 20.6121 36.8645 23.2439 36.8645L27.0073 26.662C27.6925 24.7505 29.4819 23.4783 31.4852 23.4783H52.6702C54.6735 23.4783 56.4629 24.7505 57.1481 26.662L60.8055 36.8645Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M59.4677 38.7946L55.3543 27.32C54.9415 26.1686 53.8666 25.4083 52.67 25.4083H31.485C30.2884 25.4083 29.2135 26.1686 28.8007 27.32L24.6873 38.7946H23.2437C21.6725 38.7946 20.3862 40.09 20.3862 41.7035V51.068C20.3862 52.8291 21.7075 54.2891 23.4193 54.447C29.5832 55.0157 35.7475 55.3 41.9123 55.3C48.1144 55.3 54.3872 55.0123 60.7309 54.4365C62.4447 54.281 63.7687 52.8201 63.7687 51.0571L63.7687 41.7035C63.7687 40.09 62.4825 38.7946 60.9113 38.7946H59.4677ZM61.1457 36.3965C63.9829 36.5685 66.155 38.8789 66.155 41.7035L66.155 51.0571C66.155 54.0546 63.9004 56.5669 60.9446 56.8352C54.5309 57.4173 48.1868 57.7084 41.9123 57.7084C35.6748 57.7084 29.438 57.4207 23.202 56.8453C20.2498 56.573 18 54.0623 18 51.068V41.7035C18 38.8882 20.1616 36.5735 22.9064 36.3971L26.5576 26.4986C27.3103 24.4016 29.2768 23 31.485 23H52.67C54.8789 23 56.846 24.4026 57.5982 26.5007L61.1457 36.3965ZM60.8053 36.8646C63.5431 36.8646 65.6767 39.031 65.6767 41.7035L65.6767 51.0571C65.6767 53.8132 63.6048 56.1135 60.9014 56.3588C54.5017 56.9396 48.172 57.23 41.9123 57.23C35.6895 57.23 29.4674 56.9431 23.2459 56.3691C20.5458 56.1199 18.4783 53.8211 18.4783 51.068V41.7035C18.4783 39.031 20.6119 36.8646 23.2437 36.8646L27.0071 26.6621C27.6923 24.7506 29.4817 23.4783 31.485 23.4783H52.67C54.6733 23.4783 56.4627 24.7506 57.1479 26.6621L60.8053 36.8646ZM59.8043 38.3163H60.9113C62.7536 38.3163 64.2471 39.8328 64.2471 41.7035L64.2471 51.0571C64.2471 53.0615 62.7402 54.7344 60.7741 54.9129C54.4165 55.4899 48.1293 55.7784 41.9123 55.7784C35.7327 55.7784 29.5538 55.4934 23.3753 54.9233C21.4116 54.7421 19.9079 53.0703 19.9079 51.068V41.7035C19.9079 39.8328 21.4014 38.3163 23.2437 38.3163H24.3506L28.3505 27.1586C28.8301 25.8206 30.0827 24.93 31.485 24.93H52.67C54.0723 24.93 55.3249 25.8206 55.8045 27.1586L59.8043 38.3163Z" className={className} fill={fill} />
      <path d="M28.5063 39.2591V37.8074H55.6549V39.2591H28.5063Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.0283 39.7374V37.3291H56.1335V39.7374H28.0283ZM28.5066 39.2591H55.6552V37.8074H28.5066V39.2591Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.3359 44.6211V47.4909H49.6418V44.6211H34.3359Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.3265 48.7212V51.591H47.8059V48.7212H36.3265Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5513 47.0452C22.5513 49.5339 24.54 51.564 27.0087 51.564C29.4773 51.564 31.4661 49.5339 31.4661 47.0452C31.4661 44.5564 29.4773 42.5264 27.0087 42.5264C24.54 42.5264 22.5513 44.5564 22.5513 47.0452ZM29.0798 47.0452C29.0798 48.2177 28.1457 49.1557 27.0087 49.1557C25.8717 49.1557 24.9375 48.2177 24.9375 47.0452C24.9375 45.8726 25.8717 44.9347 27.0087 44.9347C28.1457 44.9347 29.0798 45.8726 29.0798 47.0452Z" className={className} fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M52.5117 47.0452C52.5117 49.5339 54.5005 51.564 56.9691 51.564C59.4377 51.564 61.4265 49.5339 61.4265 47.0452C61.4265 44.5564 59.4377 42.5264 56.9691 42.5264C54.5005 42.5264 52.5117 44.5564 52.5117 47.0452ZM59.0403 47.0452C59.0403 48.2177 58.1061 49.1557 56.9691 49.1557C55.8321 49.1557 54.898 48.2177 54.898 47.0452C54.898 45.8726 55.8321 44.9347 56.9691 44.9347C58.1061 44.9347 59.0403 45.8726 59.0403 47.0452Z" className={className} fill={fill} />
    </svg>
  );
};

IconCar.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconCar.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => <IconCar {...props} />;

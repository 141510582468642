import React from 'react';
import PropTypes from 'prop-types';
import Script from 'nordic/script';
import Page from 'nordic/page';
import Hotjar from 'nordic/hotjar';
import HeadView from '../../components/HeadView';
import FooterView from '../../components/FooterView';
import BannerView from './components/BannerView/BannerView';
import StagesView from './components/StagesView/StagesView';
import EspecializadasView from './components/EspecializadasView/EspecializadasView';
import FeaturedAppsView from './components/FeaturedAppsView/FeaturedAppsView';
import RecommendationNewSellerView from './components/RecommendationNewSellerView/RecommendationNewSellerView';
import HeaderView from '../../components/HeaderView';
import CertificationProgramsView from './components/CertificationProgramsView/CertificationProgramsView';

const HomeView = props => {
  const {
    siteId,
    user,
    originalUrl,
    deviceType,
    stages,
    featuredApps,
    isMobile,
    baseUrl,
    mlNavigationConfigItems,
    specializedApps,
    specializedSolutions,
    analyticsCode,
    melidata,
    canonicalUrl = '',
    menuItems,
  } = props;

  const title = 'Centro de Partners';
  const menu = [];
  const redirectUrl = '';
  const pathLogo = '';

  return (
    <Page name="home" state={props} melidata={melidata}>
      <Hotjar id={3157827} triggers={['home']} />

      <HeadView
        siteId={siteId}
        mlNavigationConfigItems={mlNavigationConfigItems}
        linkCanonical={canonicalUrl}
      />
      <Script src="vendor.js" />

      <HeaderView
        isScrollable
        pathLogo={pathLogo}
        user={user}
        originalUrl={originalUrl}
        isMobile={isMobile}
        menu={menu}
        title={title}
        refererURL={redirectUrl}
        siteId={siteId}
        baseUrl={baseUrl}
        mlNavigationConfigItems={mlNavigationConfigItems}
        analyticsCode={analyticsCode}
        menuItems={menuItems}
      />

      <BannerView
        siteId={siteId}
        isMobile={isMobile}
      />

      <StagesView
        className="section--blue-circles"
        deviceType={deviceType}
        stages={stages}
        baseUrl={baseUrl}
        pageType="home"
      />

      <EspecializadasView
        baseUrl={baseUrl}
        deviceType={deviceType}
        apps={specializedApps}
        solutions={specializedSolutions}
        siteId={siteId}
      />

      <FeaturedAppsView
        baseUrl={baseUrl}
        featuredApps={featuredApps}
      />

      <RecommendationNewSellerView
        mlNavigationConfigItems={mlNavigationConfigItems}
        siteId={siteId}
        deviceType={deviceType}
      />

      <CertificationProgramsView
        mlNavigationConfigItems={mlNavigationConfigItems}
        siteId={siteId}
      />

      <FooterView
        user={user}
        refererURL={redirectUrl}
        mlNavigationConfigItems={mlNavigationConfigItems}
      />
    </Page>
  );
};

HomeView.propTypes = {
  siteId: PropTypes.string,
  user: PropTypes.shape(),
  deviceType: PropTypes.string,
  stages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    sites: PropTypes.arrayOf(PropTypes.string),
    sf_id: PropTypes.number,
    mlibre: PropTypes.bool,
    mshops: PropTypes.bool,
  })),
  isMobile: PropTypes.bool,
  baseUrl: PropTypes.string,
  canonicalUrl: PropTypes.string,
};

export default HomeView;

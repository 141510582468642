import React from 'react';
import PropTypes from 'prop-types';

const GoldBadgeIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 80 80">
    <defs>
      <path id="a" d="M0 69.574h53.159V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(13 5)">
      <path stroke="#000" d="M16.977 40.598L4.261 25.558a15.003 15.003 0 0 0-3.205 9.293c0 6.517 4.138 12.067 9.93 14.168M12.029 42.843l-6.743-7.279M36.182 40.598l12.716-15.04a15.003 15.003 0 0 1 3.205 9.293c0 6.517-4.138 12.067-9.93 14.168M41.13 42.843l6.743-7.279" />
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path fill="#FFF048" d="M20.817 23.448h10.981V.5H20.817zM45.67 44.743c0 10.595-8.59 19.185-19.184 19.185-10.595 0-19.184-8.59-19.184-19.185 0-10.596 8.589-19.185 19.184-19.185s19.185 8.59 19.185 19.185" mask="url(#b)" />
      <path fill="#FFF" d="M40.856 44.743c0 7.937-6.434 14.37-14.37 14.37-7.937 0-14.37-6.433-14.37-14.37 0-7.937 6.433-14.37 14.37-14.37 7.936 0 14.37 6.433 14.37 14.37" mask="url(#b)" />
      <path stroke="#000" d="M45.67 44.743c0 10.595-8.59 19.185-19.184 19.185-10.595 0-19.184-8.59-19.184-19.185 0-10.596 8.589-19.185 19.184-19.185s19.185 8.59 19.185 19.185z" mask="url(#b)" />
      <path fill="#FFF048" d="M35.597 33.632a14.303 14.303 0 0 1 3.259 9.11c0 7.937-6.434 14.37-14.37 14.37a14.307 14.307 0 0 1-9.111-3.258 14.34 14.34 0 0 0 11.112 5.259c7.936 0 14.37-6.433 14.37-14.37 0-4.477-2.05-8.476-5.26-11.111" mask="url(#b)" />
      <path stroke="#000" strokeLinecap="round" d="M19.128 23.447l-4.294-4.294V.5h23.299v18.723l-4.083 4.083" mask="url(#b)" />
      <path stroke="#000" d="M40.856 44.743c0 7.937-6.434 14.37-14.37 14.37-7.937 0-14.37-6.433-14.37-14.37 0-7.937 6.433-14.37 14.37-14.37 7.936 0 14.37 6.433 14.37 14.37z" mask="url(#b)" />
      <path fill="#FFF048" d="M27.007 36.18l2.063 4.18a.78.78 0 0 0 .587.426l4.614.67a.78.78 0 0 1 .432 1.33l-3.34 3.255a.782.782 0 0 0-.222.69l.788 4.595a.781.781 0 0 1-1.133.823l-4.126-2.17a.775.775 0 0 0-.725 0l-4.126 2.17a.78.78 0 0 1-1.132-.823l.788-4.594a.783.783 0 0 0-.225-.691l-3.338-3.254a.78.78 0 0 1 .433-1.33l4.612-.671a.782.782 0 0 0 .588-.426l2.063-4.18a.78.78 0 0 1 1.399 0" mask="url(#b)" />
      <path stroke="#000" d="M27.007 36.18l2.063 4.18a.78.78 0 0 0 .587.426l4.614.67a.78.78 0 0 1 .432 1.33l-3.34 3.255a.782.782 0 0 0-.222.69l.788 4.595a.781.781 0 0 1-1.133.823l-4.126-2.17a.775.775 0 0 0-.725 0l-4.126 2.17a.78.78 0 0 1-1.132-.823l.788-4.594a.783.783 0 0 0-.225-.691l-3.338-3.254a.78.78 0 0 1 .433-1.33l4.612-.671a.782.782 0 0 0 .588-.426l2.063-4.18a.78.78 0 0 1 1.399 0zM26.486 65.85a23.21 23.21 0 0 1-10.94-2.72" mask="url(#b)" />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M22.767 65.868c-.834 2.484-2.595 3.715-5.79 3.007 1.259-3.02 3.278-3.753 5.79-3.007zM15.746 63.44c-2.425.995-4.508.468-6.062-2.41 3.107-1.026 4.983.021 6.062 2.41z" mask="url(#b)" />
      <path stroke="#000" d="M26.523 65.85a23.21 23.21 0 0 0 10.941-2.72" mask="url(#b)" />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M30.242 65.868c.835 2.484 2.596 3.715 5.79 3.007-1.258-3.02-3.277-3.753-5.79-3.007zM37.264 63.44c2.425.995 4.508.468 6.062-2.41-3.107-1.026-4.983.021-6.062 2.41z" mask="url(#b)" />
      <path fill="#FFF" d="M27.988 65.85a1.503 1.503 0 1 1-3.005-.002 1.503 1.503 0 0 1 3.005.002" mask="url(#b)" />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M27.988 65.85a1.503 1.503 0 1 1-3.005-.002 1.503 1.503 0 0 1 3.005.002z" mask="url(#b)" />
    </g>
  </svg>
);

GoldBadgeIcon.defaultProps = {
  className: '',
  width: 50,
  height: 50,
};

GoldBadgeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default props => <GoldBadgeIcon {...props} />;


/* eslint-disable react/no-array-index-key */
/**
 * Module dependencies
 */
import React from 'react';
import { Title, Text } from '@andes/typography';
import { useI18n } from 'nordic/i18n';
import Image from 'nordic/image';

import CardAppItem from '../../../../components/CardAppItem';

/**
 * FeaturedAppsView Component
 */
const FeaturedAppsView = (props) => {
  const { i18n } = useI18n();
  const { baseUrl, featuredApps } = props;

  const texts = {
    title: i18n.gettext('Destacadas'),
    subtitle: i18n.gettext(
      'Encuentra soluciones certificadas, que se adapten a las necesidades de tu negocio.',
    ),
    description: i18n.gettext(
      'Contamos con más de 40 soluciones certificadas, con distintos niveles de certificación como Platinum, Gold y Silver, para acompañar el momento que tu negocio está atravesando.',
    ),
    mshops: i18n.gettext('Se integra con'),
  };

  if (featuredApps && featuredApps.length === 0) return null;

  return (
    <section className="section section-home-featured-apps">
      <div className="container">
        <div className="section-home-featured-apps__header">
          <div>
            <Title
              component="h2"
              size="xl"
              color="inverted"
              className="section-home-featured-apps__title"
            >
              {texts.title}
            </Title>
            <Text
              color="inverted"
              size="m"
              className="section-home-featured-apps__subtitle"
            >
              {texts.subtitle}
            </Text>
            <Text
              component="p"
              color="inverted"
              size="l"
              className="section-home-featured-apps__description"
            >
              {texts.description}
            </Text>
          </div>
          <div>
            <Image
              src="https://http2.mlstatic.com/storage/developers-site-cms-admin/CDP/242713098398-medal-podium.png"
              width="287"
              alt={texts.title}
            />
          </div>
        </div>
        <div className="section-home-featured-apps__list">
          {featuredApps && featuredApps.slice(0, 6).map((featuredApp, featuredAppIndex) => (
            <CardAppItem key={featuredAppIndex} {...featuredApp} baseUrl={baseUrl} showBus />
          ))}
        </div>
      </div>
    </section>
  );
};

FeaturedAppsView.propTypes = {};

export default FeaturedAppsView;

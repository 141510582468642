import React from 'react';
import PropTypes from 'prop-types';

const IconUser = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.999 15.75C15.7275 15.75 18.75 12.7275 18.75 8.99905C18.75 5.27057 15.7275 2.24805 11.999 2.24805C8.27053 2.24805 5.248 5.27057 5.248 8.99905C5.248 12.7275 8.27053 15.75 11.999 15.75ZM11.999 14.25C9.09895 14.25 6.748 11.8991 6.748 8.99905C6.748 6.099 9.09895 3.74805 11.999 3.74805C14.899 3.74805 17.25 6.099 17.25 8.99905C17.25 11.8991 14.899 14.25 11.999 14.25Z"
      fill="black"
      fillOpacity={0.55}
    />
    <path
      d="M5.98045 18.75C4.74861 18.75 3.75 19.7487 3.75 20.9805V21.7305H2.25V20.9805C2.25 18.9202 3.92018 17.25 5.98045 17.25H18C20.0711 17.25 21.75 18.929 21.75 21V21.75H20.25V21C20.25 19.7574 19.2426 18.75 18 18.75H5.98045Z"
      fill="black"
      fillOpacity={0.55}
    />
  </svg>
);
IconUser.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
IconUser.defaultProps = {
  fill: '#737373',
  width: 23,
  height: 31,
  className: '',
};
export default (props) => <IconUser {...props} />;


import React from 'react';
import PropTypes from 'prop-types';

const IconReport = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <path fill={fill} className={className} fillRule="nonzero" d="M23 15.5v-1h11v1zM19 15.5v-1h2v1zM19 27.5v-1h2v1zM19 21.5v-1h2v1zM19 33.5v-1h2v1zM23 27.5v-1h11v1zM23 21.5v-1h11v1zM23 33.5v-1h11v1z" />
        <path fill={fill} className={className} fillRule="nonzero" d="M13.5 19.5v-10h24V36a2.5 2.5 0 0 1-2.5 2.5H12A2.5 2.5 0 0 1 9.5 36V19.5h4zm21.5 18a1.5 1.5 0 0 0 1.5-1.5V10.5h-22V36c0 .563-.186 1.082-.5 1.5h21zm-24.5-17V36a1.5 1.5 0 0 0 3 0V20.5h-3z" />
      </g>
    </svg>
  );
};

IconReport.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconReport.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default props => (
  <IconReport {...props} />
);

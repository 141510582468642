import React from 'react';
import PropTypes from 'prop-types';

const PlatinumBadgeIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 80 80">
    <defs>
      <path id="a" d="M0 72.055h67.133V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(6 4)">
      <path stroke="#000" d="M43.28 36.666l18.651-22.06a22.006 22.006 0 0 1 4.703 13.63c0 9.56-6.07 17.701-14.565 20.783M50.538 39.96l9.892-10.679" />
      <path stroke="#000" d="M52.688 48.75l9.062 1.022a9.233 9.233 0 0 1-3.658 4.82 9.264 9.264 0 0 1-8.467.955M23.852 36.666l-18.65-22.06A22.005 22.005 0 0 0 .5 28.236c0 9.56 6.07 17.701 14.564 20.783M16.595 39.96L6.703 29.281" />
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path fill="#A1CEC0" d="M27.693 23.448h10.98V.5h-10.98zM52.546 44.743c0 10.595-8.59 19.184-19.184 19.184-10.595 0-19.184-8.589-19.184-19.184 0-10.596 8.589-19.185 19.184-19.185s19.184 8.59 19.184 19.185" mask="url(#b)" />
      <path fill="#FFF" d="M47.732 44.743c0 7.937-6.434 14.37-14.37 14.37-7.937 0-14.37-6.433-14.37-14.37 0-7.937 6.433-14.37 14.37-14.37 7.936 0 14.37 6.433 14.37 14.37" mask="url(#b)" />
      <path stroke="#000" d="M52.546 44.743c0 10.595-8.59 19.184-19.184 19.184-10.595 0-19.184-8.589-19.184-19.184 0-10.596 8.589-19.185 19.184-19.185s19.184 8.59 19.184 19.185z" mask="url(#b)" />
      <path fill="#A1CEC0" d="M42.473 33.632a14.309 14.309 0 0 1 3.259 9.11c0 7.937-6.434 14.37-14.37 14.37a14.318 14.318 0 0 1-9.112-3.258 14.34 14.34 0 0 0 11.113 5.259c7.936 0 14.37-6.433 14.37-14.37a14.34 14.34 0 0 0-5.26-11.111" mask="url(#b)" />
      <path stroke="#000" strokeLinecap="round" d="M26.003 23.447l-4.294-4.294V.5h23.3v18.723l-4.083 4.083" mask="url(#b)" />
      <path stroke="#000" d="M47.732 44.743c0 7.937-6.434 14.37-14.37 14.37-7.937 0-14.37-6.433-14.37-14.37 0-7.937 6.433-14.37 14.37-14.37 7.936 0 14.37 6.433 14.37 14.37z" mask="url(#b)" />
      <path fill="#A1CEC0" d="M33.883 36.18l2.063 4.18a.78.78 0 0 0 .587.426l4.613.67a.78.78 0 0 1 .432 1.33l-3.338 3.255a.785.785 0 0 0-.224.69l.788 4.595a.78.78 0 0 1-1.132.823l-4.126-2.17a.775.775 0 0 0-.725 0l-4.127 2.17a.78.78 0 0 1-1.132-.823l.788-4.594a.78.78 0 0 0-.224-.691l-3.34-3.254a.78.78 0 0 1 .434-1.33l4.613-.671a.778.778 0 0 0 .587-.426l2.064-4.18a.78.78 0 0 1 1.399 0" mask="url(#b)" />
      <path stroke="#000" d="M33.883 36.18l2.063 4.18a.78.78 0 0 0 .587.426l4.613.67a.78.78 0 0 1 .432 1.33l-3.338 3.255a.785.785 0 0 0-.224.69l.788 4.595a.78.78 0 0 1-1.132.823l-4.126-2.17a.775.775 0 0 0-.725 0l-4.127 2.17a.78.78 0 0 1-1.132-.823l.788-4.594a.78.78 0 0 0-.224-.691l-3.34-3.254a.78.78 0 0 1 .434-1.33l4.613-.671a.778.778 0 0 0 .587-.426l2.064-4.18a.78.78 0 0 1 1.399 0zM31.362 65.85c-3.954 0-7.679-.983-10.942-2.72" mask="url(#b)" />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M27.642 65.868c-.834 2.484-2.595 3.715-5.79 3.007 1.26-3.02 3.279-3.753 5.79-3.007zM20.622 63.44c-2.425.995-4.508.469-6.062-2.41 3.107-1.026 4.982.022 6.062 2.41z" mask="url(#b)" />
      <path stroke="#000" d="M36.4 65.85c3.953 0 7.677-.983 10.94-2.72" mask="url(#b)" />
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M40.118 65.868c.834 2.484 2.596 3.715 5.79 3.007-1.258-3.02-3.278-3.753-5.79-3.007zM47.14 63.44c2.424.995 4.507.469 6.062-2.41-3.108-1.026-4.983.022-6.062 2.41z" mask="url(#b)" />
      <path stroke="#000" d="M14.445 48.75l-9.063 1.022a9.24 9.24 0 0 0 3.66 4.82 9.262 9.262 0 0 0 8.465.955" mask="url(#b)" />
      <path fill="#FFF" d="M38.089 65.567l-4.404 1.119 1.18-4.342 4.365-1.078z" mask="url(#b)" />
      <path stroke="#000" d="M38.089 65.567l-4.404 1.119 1.18-4.342 4.365-1.078z" mask="url(#b)" />
      <path fill="#FFF" d="M29.281 65.567l4.403 1.119-1.18-4.342-4.364-1.078z" mask="url(#b)" />
      <path stroke="#000" d="M29.281 65.567l4.403 1.119-1.18-4.342-4.364-1.078z" mask="url(#b)" />
      <path fill="#FFF" d="M36.749 65.812l-3.123 5.25-3.006-5.25 3.124-5.175z" mask="url(#b)" />
      <path stroke="#000" d="M36.749 65.812l-3.123 5.25-3.006-5.25 3.124-5.175z" mask="url(#b)" />
    </g>
  </svg>
);

PlatinumBadgeIcon.defaultProps = {
  className: '',
  width: 50,
  height: 50,
};

PlatinumBadgeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default props => <PlatinumBadgeIcon {...props} />;

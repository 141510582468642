
import React from 'react';
import PropTypes from 'prop-types';

const IconShakeHands = (props) => {
  const { fill, width, height, className } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={className}
        d="M32.2268 32.9886L47.9327 26L58.8641 32.9886H65.0501V30.7261H73V32.9886H67.7023L67.7023 48.8525L67.7075 48.8525L67.7023 49.9812V53.3508H73V55.6132H65.0501V51.106L61.2635 51.0933C61.6892 52.2638 61.3514 53.5847 60.2753 54.5017L56.1442 58.0218C52.8807 60.8026 48.0308 61.7302 43.6879 60.4042L42.5226 60.0484C40.0919 60.9613 37.15 60.5684 35.1594 58.8698L26.0484 51.0898L19.9565 51.1068V55.6132H12V53.3508H17.3043V49.981L17.3 48.8517L17.3043 48.8517V32.9886H12V30.7261H19.9565V32.9886H32.2268ZM19.9565 48.8443L26.0453 48.8274L29.8685 45.5674C32.4588 43.3594 36.657 43.3607 39.2454 45.5703L44.9937 50.4824C47.5556 52.67 47.5794 56.2016 45.0707 58.4105C48.3188 59.2328 51.8535 58.4801 54.2699 56.4211L58.401 52.901C58.8915 52.483 58.9218 51.8132 58.4704 51.3643L44.6829 37.6493L38.881 38.8869C35.9344 39.5155 32.8639 38.3557 31.4288 36.0721L30.9129 35.251H19.9565V48.8443ZM42.2451 57.5606L42.2664 57.5672C42.4021 57.4782 42.5325 57.3808 42.6567 57.2749L43.1155 56.8799C44.6689 55.5551 44.6698 53.407 43.1171 52.0811L37.3698 47.1699C35.8163 45.8438 33.2974 45.843 31.7433 47.1677L28.4714 49.9576L37.0344 57.2697C38.4538 58.4808 40.678 58.5861 42.2333 57.5886L42.2451 57.5606ZM65.0501 35.251V48.8436L59.4112 48.8246L45.6294 35.1153L38.2376 36.6921C36.4697 37.0692 34.6273 36.3733 33.7663 35.0031L33.6937 34.8876L47.6786 28.6648L57.9806 35.251H65.0501Z"
        fill={fill}
      />
    </svg>
  );
};

IconShakeHands.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

IconShakeHands.defaultProps = {
  fill: '#3483FA',
  width: 86,
  height: 86,
  className: '',
};

export default (props) => <IconShakeHands {...props} />;
